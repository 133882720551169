<div class="container-fluid"   [ngClass]="isAdmin ? 'overlay_filter1' : 'overlay_filter'"
[ngClass]="{'overlay_filter2': route === 'home'}">
  <!-- *ngIf="(route === 'properties-list') || (route === 'home' && !isMobileOrTablet)" -->

  <ng-container *ngIf="isMobile && route === 'home'">
    <div class="col">
      <div class="addProperty__dropdown location">
        <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode" label="Location"
                               [labelClass]="'filter__dropdownLabel'" [initialLocation]="selectedLocation" [showStar]="false"
                               className="'filter-maps-irr filter-maps-irr-error'">
        </AutocompleteComponent>
      </div>
    </div>
    <div class="col-auto mt-4">
      <app-common-button (click)="navigateToProperties()" [faIcon]="faSearch" buttonClass="smaiifilledbutton"
                         buttonLabel="Search" class=" search-button"></app-common-button>
    </div>
  </ng-container>

  <ng-container *ngIf="isTab && route === 'home'" class="tab">
    <div class="col">
      <div class="addProperty__dropdown">
        <label class="addProperty__dropdownLabel custom-dropdown-container">Property Type</label>
        <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow"
                [(ngModel)]="selectedPropertyType">
          <option [value]="null">Select Property</option>
          <option *ngFor="let category of propertyList" [ngValue]="category?.name">
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col">
      <div class="addProperty__dropdown location">
        <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode" label="Location"
                               [labelClass]="'filter__dropdownLabel'" [initialLocation]="selectedLocation" [showStar]="false"
                               className="'filter-maps-irr filter-maps-irr-error'">
        </AutocompleteComponent>
      </div>
    </div>
    <div class="col-auto mt-4">
      <app-common-button (click)="navigateToProperties()" [faIcon]="faSearch" buttonClass="smaiifilledbutton"
                         buttonLabel="Search" class=" search-button"></app-common-button>
    </div>
  </ng-container>


  <div class="row justify-content-between" *ngIf="(route === 'home' && !isMobile && !isTab) || (route === 'properties-list')">
    <!-- Property Type Dropdown -->
    <div class="col">
      <div class="addProperty__dropdown">
        <label class="addProperty__dropdownLabel custom-dropdown-container">Property Type</label>
        <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow"
                [(ngModel)]="selectedPropertyType">
          <option [value]="null">Select Property</option>
          <option *ngFor="let category of propertyList" [ngValue]="category?.name">
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>
    <!-- Location Autocomplete -->
    <div class="col"   [ngClass]="isAdmin ? 'location1' : 'loc'"    >
      <div class="addProperty__dropdown location">
        <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode" label="Location"
                               [labelClass]="'filter__dropdownLabel'" [initialLocation]="selectedLocation" [showStar]="false"
                               className="'filter-maps-irr filter-maps-irr-error'">
        </AutocompleteComponent>
      </div>
    </div>
    <!-- Budget Dropdown -->
    <div class="col">
      <div class="addProperty__dropdown">
        <label class="addProperty__dropdownLabel">Min Amount</label>
        <select class="addProperty__dropdownOptions custom-dropdown-arrow" [(ngModel)]="selectedMinBudget"
                (change)="onMinBudgetChange($event)">
          <option value="null">Select Min</option>
          <option *ngFor="let budget of minBudgetOptions" [value]="budget.value ">
            {{ budget.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="col" [ngClass]="{'max': isAdmin !== true}">
      <div class="addProperty__dropdown">
        <label class="addProperty__dropdownLabel">Max Amount</label>
        <select class="addProperty__dropdownOptions custom-dropdown-arrow" [(ngModel)]="selectedMaxBudget"
                (change)="onMaxBudgetChange($event)">
          <option value="null">Select Max</option>
          <option *ngFor="let budget of maxBudgetOptions" [value]="budget.value ">
            {{ budget.label }}
          </option>
        </select>
      </div>
    </div>
    <!-- Amenities Dropdown -->
    <div class="col">
      <div class="custom-dropdown addProperty__dropdown amenity">
        <label class="addProperty__dropdownLabel">Amenities</label>
        <div class="dropdown-header addProperty__dropdownOptions custom-dropdown-arrow"
             [ngClass]="{'amenities': isAdmin === true}" (click)="toggleDropdown()">
          <span *ngIf="selectedAmenities.length === 0">Select Amenities</span>
          <span *ngIf="selectedAmenities.length === 1">{{ selectedAmenities[0]?.name }}</span>
          <span *ngIf="selectedAmenities.length > 1">{{ selectedAmenities[0]?.name }} +{{
              selectedAmenities.length -
              1 }} more</span>
        </div>
        <div class="dropdown-list" *ngIf="dropdownOpen">
          <div *ngFor="let amenity of amenitiesdata" class="dropdown-item propet-s">
            <label>
              <input type="checkbox" [checked]="isSelected(amenity)" (change)="toggleSelection(amenity)">
              {{ amenity.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Popularity Dropdown -->
    <div class="col">
      <div class="addProperty__dropdown amenity">
        <label class="addProperty__dropdownLabel">Popularity</label>
        <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow custom-select"
                [(ngModel)]="selectedPopularity" (change)="onPopularityChange($event)">
          <option [value]="null">Select Popularity</option>
          <option [value]="'mostViewedAsc'">Most Viewed (Low to High)</option>
          <option [value]="'mostViewedDesc'">Most Viewed (High to Low)</option>
        </select>
      </div>
    </div>
    <!-- Search Button -->
    <div class="col-auto mt-4 search" [ngClass]="{'serch': isAdmin === false}">
      <app-common-button (click)="navigateToProperties()" [faIcon]="faSearch" buttonClass="smaiifilledbutton"
                         buttonLabel="Search" class="search-button"></app-common-button>
    </div>
  </div>
</div>
<div class="form-check form-switch" *ngIf="checkLoginStatus && !isAdmin && route !== 'home'">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
         (change)="onToggleFavourite($event)">
  <label class="form-check-label" for="flexSwitchCheckDefault">Saved Properties
  </label>
</div>


<!-- <div *ngIf="route === 'home' && isMobileOrTablet" type="button" (click)="toggleOffCanvas()">
    <fa-icon [icon]="faFilter"  class="filters-icon"></fa-icon>
</div> -->

<div *ngIf="route === 'home' && isMobileOrTablet">
  <div class="overlay" *ngIf="isOffCanvasOpen" (click)="toggleOffCanvas()"></div>
  <div class="off-canvas width-350" [ngClass]="{'show': isOffCanvasOpen}">
    <div class="offCanvas__container">
      <div class="offCanvas__header">
        <div class="offCanvas__title">
          Filters
        </div>
        <div class="offCanvas__Iconscontainer">
          <div class="Offcanvas__cancelIcon" (click)="toggleOffCanvas()">
            <img src="../../../assets/images/icons8-cancel.svg" alt="Close Menu">
          </div>
        </div>
      </div>
      <div class="offCanvas__contentWrapper">
        <ul class="offCanvas__menu">
          <li>
            <label class="addProperty__dropdownLabel">Property Type</label>
            <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow"
                    [(ngModel)]="selectedPropertyType">
              <option [value]="null">Select Property</option>
              <option *ngFor="let category of propertyList" [ngValue]="category?.name">
                {{ category.name }}
              </option>
            </select>
          </li>

          <!-- Location Autocomplete -->
          <li>
            <AutocompleteComponent (setAddress)="formatAddress($event)" adressType="geocode"
                                   label="Location" [labelClass]="'filter__dropdownLabel'" [initialLocation]="selectedLocation"
                                   [showStar]="false" className="'filter-maps-irr filter-maps-irr-error'">
            </AutocompleteComponent>
          </li>

          <!-- Min Amount Dropdown -->
          <li>
            <label class="addProperty__dropdownLabel">Min Amount</label>
            <select class="addProperty__dropdownOptions custom-dropdown-arrow"
                    [(ngModel)]="selectedMinBudget" (change)="onMinBudgetChange($event)">
              <option value="null">Select Min</option>
              <option *ngFor="let budget of minBudgetOptions" [value]="budget.value ">
                {{ budget.label }}
              </option>
            </select>
          </li>

          <!-- Max Amount Dropdown -->
          <li>
            <label class="addProperty__dropdownLabel">Max Amount</label>
            <select class="addProperty__dropdownOptions custom-dropdown-arrow"
                    [(ngModel)]="selectedMaxBudget" (change)="onMaxBudgetChange($event)">
              <option value="null">Select Max</option>
              <option *ngFor="let budget of maxBudgetOptions" [value]="budget.value ">
                {{ budget.label }}
              </option>
            </select>
          </li>

          <!-- Amenities Dropdown -->
          <li>
            <label class="addProperty__dropdownLabel">Amenities</label>
            <div class="dropdown-header addProperty__dropdownOptions custom-dropdown-arrow"
                 [ngClass]="{'amenities': isAdmin === true}" (click)="toggleDropdown()">
              <span *ngIf="selectedAmenities.length === 0">Select Amenities</span>
              <span *ngIf="selectedAmenities.length === 1">{{ selectedAmenities[0]?.name }}</span>
              <span *ngIf="selectedAmenities.length > 1">{{ selectedAmenities[0]?.name }} +{{
                  selectedAmenities.length - 1 }} more</span>
            </div>
            <div class="dropdown-list" *ngIf="dropdownOpen">
              <div *ngFor="let amenity of amenitiesdata" class="dropdown-item propet-s">
                <label>
                  <input type="checkbox" [checked]="isSelected(amenity)"
                         (change)="toggleSelection(amenity)">
                  {{ amenity.name }}
                </label>
              </div>
            </div>
          </li>

          <!-- Popularity Dropdown -->
          <li>
            <label class="addProperty__dropdownLabel">Popularity</label>
            <select class="addProperty__dropdownOptions propet-s custom-dropdown-arrow custom-select"
                    [(ngModel)]="selectedPopularity" (change)="onPopularityChange($event)">
              <option [value]="null">Select Popularity</option>
              <option [value]="'mostViewedAsc'">Most Viewed (Low to High)</option>
              <option [value]="'mostViewedDesc'">Most Viewed (High to Low)</option>
            </select>
          </li>
        </ul>
      </div>
      <div class="offCanvas__footer">
        <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Cancel"
                           (click)="toggleOffCanvas()"></app-common-button>
        <app-common-button (click)="navigateToProperties()" [faIcon]="faSearch" buttonClass="smaiifilledbutton"
                           buttonLabel="Search" class=" search-button"></app-common-button>
      </div>
    </div>
  </div>
</div>
