import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPhone, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { LoginService } from '../../services/login/login.service';
import { PropertyService } from '../../services/property/property.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-new',
  templateUrl: './login-new.component.html',
  styleUrls: ['./login-new.component.scss']
})
export class LoginNewComponent implements OnInit {
  loginForm!: FormGroup;
  showForm2: boolean = false;
  showform1: boolean = true;
  showForm3: boolean = false;
  verifyLoader: boolean = false;
  errorMessage: any;
  userDetails: any;
  signupForm!: FormGroup;
  createForm!: FormGroup;
  hide = true;

  public readonly faPhone = faPhone;
  public readonly faLock = faLock;
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private router: Router,
    private propertyService: PropertyService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.generateLoginForm();
    this.generateSignUpForm();
    this.generateCreateForm();
  }

  generateLoginForm() {
    this.loginForm = this.fb.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(RegExpPatterns.MobilePattern)]],
    });
  }

  generateSignUpForm() {
    this.signupForm = this.fb.group({
      email: ['', [Validators.pattern(RegExpPatterns.EmailPattern)]],
      otp: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  generateCreateForm() {
    this.createForm = this.fb.group({
      phoneNumber: [''],
      otp: ['', [Validators.required]],
    });
  }

  generateOtp() {
    this.verifyLoader = true;
    const credentials = {
      phoneNumber: this.loginForm.value.mobileNumber,
    };
    this.loginService.generateOtp(credentials).subscribe({
      next: (res: any) => {
        this.verifyLoader = false;
        const mobileNumber = this.loginForm.value.mobileNumber;
        if (res.data.isExists == false) {
          this.signupForm.patchValue({ phoneNumber: mobileNumber });
          this.showForm2 = true;
          this.showform1 = false;
          this.showForm3 = false;
        }
        if (res.data.isExists == true) {
          this.createForm.controls['phoneNumber'].setValue(mobileNumber);
          this.showForm3 = true;
          this.showForm2 = false;
          this.showform1 = false;
        }
      },
      error: (error: any) => {
        this.verifyLoader = false;
        this.errorMessage = error?.error?.error?.message;
      },
    });
  }

  submitOtp() {
    this.verifyLoader = true;
    const credentials = {
      phoneNumber: this.loginForm.value.mobileNumber,
      otp: this.createForm.value.otp || this.signupForm.value.otp,
      name: this.signupForm.value.name,
      email: this.signupForm.value.email,
    };
    this.loginService.submitOtp(credentials).subscribe({
      next: (res: any) => {
        if (res?.data) {
          this.verifyLoader = false;
        }
        if (res?.data?.token) {
          this.storageService.setDataToLocalStorage(StorageKeys.ACCESS_TOKEN, res.data.token);
        }
        this.getuserDetails();
      },
      error: (error: any) => {
        this.verifyLoader = false;
        this.errorMessage = error?.error?.error?.message;
      },
    });
  }

  getuserDetails() {
    this.propertyService.getuserDetails()?.subscribe({
      next: (res: any) => {
        this.userDetails = res?.data;
        if (this.userDetails) {
          this.storageService.setDataToLocalStorage(StorageKeys.NAME, res.data.name);
          this.storageService.setDataToLocalStorage(StorageKeys.PROFILE, res?.data?.profileUrl?.objectUrl);
          this.storageService.setDataToLocalStorage(StorageKeys.EMAIL, res?.data?.email);
          this.storageService.setDataToLocalStorage(StorageKeys.enrollmentType, res.data.enrollmentType);
          this.storageService.setDataToLocalStorage(StorageKeys.PHONE_NUMBER, res.data.phoneNumber);
          this.storageService.setDataToLocalStorage(StorageKeys.USER_ID, res.data.user);
        }
        this.propertyList();
      },
      error: (error: any) => {},
    });
  }

  home() {
    this.router.navigate(['/home']);
  }

  propertyList() {
    this.router.navigate(['/properties-list']);
  }

  onEnterKey(event: Event, formType: string) {
    event.preventDefault();
    const keyboardEvent = event as KeyboardEvent;
    if (formType === 'loginForm' && this.loginForm.valid) {
      this.generateOtp();
    } else if (formType === 'signupForm' && this.signupForm.valid) {
      this.submitOtp();
    } else if (formType === 'createForm' && this.createForm.valid) {
      this.submitOtp();
    }
  }
}
