import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCamera, faUserCircle, faUsers, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { RegExpPatterns } from '../utilites/regex-pattern';
import { PropertyService } from '../../services/property/property.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-profile-edit',
  templateUrl: './user-profile-edit.component.html',
  styleUrl: './user-profile-edit.component.scss'
})
export class UserProfileEditComponent {
  isEditprofile: boolean = false;
  isNameDisabled: boolean = true;
  profileForm            !: FormGroup;
  profileImage: any;
  showProfileImg: any;
  userDetailsData: any;
  userCircleIcon = faUserCircle;
  profileImageId: any;
  errorMessage1: any;
  errorMessage: any;
  errorMessage2: any;
  verifyLoader : boolean = false;
  @Input() userDetails: any;
  @Input() getuserDetails: any;
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @Output() profileUpdated: EventEmitter<any> = new EventEmitter<any>();
  public readonly faEdit = faEdit;
  public readonly faPen = faPen;
 
  constructor(
    private formBuilder: FormBuilder,
    private propertyService: PropertyService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.genrateProfileForm();
    this.userDetailsData = {
      name: this.storageService.getDataFromLocalStorage(
        StorageKeys.NAME
      ),
      profileImage: this.storageService.getDataFromLocalStorage(
        StorageKeys.PROFILE
      ),
      email: this.storageService.getDataFromLocalStorage(
        StorageKeys.EMAIL
      )
    }

    this.userDetailsData = this.userDetails;
    if (this.userDetailsData) {
      this.updateUserForm();
    }
  }

  genrateProfileForm() {
    this.profileForm = this.formBuilder.group({
      name: [this.userDetailsData?.name || '', [Validators.required, Validators.pattern(RegExpPatterns.name)]],
      email : [this.userDetailsData?.email ||'',[ Validators.pattern(RegExpPatterns.EmailPattern)]]
    });
  }

  updateUserForm() {
    this.profileForm.patchValue({
      name: this.userDetailsData?.name || '',
      email: this.userDetails?.email || '',
      profileImage: this.profileImage
    });
  }

  upload(event: any) {
    if (event?.target?.files.length) {
      let files = event?.target?.files[0];
      let formData = new FormData();
      formData.append('file', files, files.name);
      if (files.size / 1024 <= 5000) {
        this.propertyService.uploadFiles(formData).subscribe({
          next: (res: any) => {
            this.profileImage = res.data.objectUrl;
            this.profileImageId = res.data._id;
            this.errorMessage = res.message
              ? res.message
              : `Profile image uploaded successfully`;
              setTimeout(() => {
              this.errorMessage = '';
            }, 5000); 
          },
          error: (error: any) => {
            this.errorMessage = `Only jpeg, png, and jpg formats are allowed `;
            this.errorMessage1 = '';
              setTimeout(() => {
              this.errorMessage = '';
            }, 5000);
          }
        });
      } else {
        this.errorMessage1 = `Image size should be less than 5MB `;
          setTimeout(() => {
          this.errorMessage1 = '';
        }, 5000);
      }
    }
  }

  onCloseIconClick() {
    this.isEditprofile = false;
    this.profileImage = '';
    this.closePanel.emit();

  }


  saveProfile = () => {
    this.isEditprofile = false;
    this.verifyLoader = true;
    this.profileForm.markAllAsTouched();


    const payload = {
      document: this.profileImageId,
      name: this.profileForm.value.name,
      email: this.profileForm.value.email || '',

    };

    this.propertyService.saveProfile(payload)?.subscribe({
      next: (res: any) => {
        this.verifyLoader = false;
        this.closePanel.emit();
        this.profileUpdated.emit();
      }, error: (eror: any) => {
        this.verifyLoader = false;
      }
    })

    if (payload) {
      this.storageService.setDataToLocalStorage(
        StorageKeys.NAME,
        payload.name
      ),
        this.storageService.setDataToLocalStorage(
          StorageKeys.PROFILE,
          this.profileImage
        ),
        this.storageService.setDataToLocalStorage(
          StorageKeys.EMAIL,
          payload.email
        )
    }

    this.userDetailsData = {
      name: this.storageService.getDataFromLocalStorage(
        StorageKeys.NAME
      ),
      profileImage: this.storageService.getDataFromLocalStorage(
        StorageKeys.PROFILE
      ),
      email: this.storageService.getDataFromLocalStorage(
        StorageKeys.EMAIL
      )
    }
  }

  protected readonly faCamera = faCamera;
}
