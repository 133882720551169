import { Component } from '@angular/core';

@Component({
  selector: 'app-investors-reviews',
  templateUrl: './investors-reviews.component.html',
  styleUrl: './investors-reviews.component.scss'
})
export class InvestorsReviewsComponent {
  reviews = [
    {
      title: 'Meet the Founder',
      description: 'At the heart of Propertybox is Sunoj Mysore,a visionary leader with a passion for transforming the real estate experience.With 10 years of expertise in property management,technology,and customer service,Sunoj Mysore recognized the need for a smarter,more streamlined solution to connect buyers,sellers,and renters with their dream properties.',
      name: 'Sunoj Mysore',
      designation: 'Founder',
      image: "assets/images/f-1.jpg"
    },
    {
      title: 'Meet the Founder',
      description: 'Ram Prayaga founded Propertybox with the mission to simplify real estate transactions by leveraging cutting-edge technology and a user-first approach. Every feature, from intuitive property searches to secure payment gateways, reflects their dedication to innovation and excellence in the real estate industry.Driven by a belief in transparency, trust, and accessibility,Propertybox has become a trusted partner for thousands of users, thanks to Ram Prayaga’s commitment to turning challenges into opportunities.',
      name: ' Ram Prayaga',
      designation: 'Founder',
      image: "assets/images/f-2.jpg"
    },
    {
      title: 'Our Vision',
      description: 'To create a world where finding and managing properties is effortless, empowering individuals and communities to thrive.',
      name: 'Sunoj Mysore',
      designation: 'Founder',
      image: "assets/images/f-1.jpg"
    },
    {
      title: 'Meassage From The Founder',
      description: 'Real estate is more than just properties—it’s about people, dreams, and possibilities. With Propertybox, I wanted to create a platform that truly empowers users, whether they’re searching for their first home or managing a diverse portfolio. Thank you for trusting us to be part of your journey.',
      name: ' Ram Prayaga',
      designation: 'Founder',
      image: "assets/images/f-2.jpg"
    },
  ];

}
