import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MasterDataComponent } from './master-data/master-data.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HomeComponent } from './home/home.component';
import { LoginNewComponent } from './login-new/login-new.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { AppComponent } from './app.component';
import { AddPropertiesComponent } from './add-properties/add-properties.component';
import { PropertyListComponent } from './property-list/property-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EnquiriesComponent } from './enquiries/enquiries.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AddNewPropertiesComponent } from './add-new-properties/add-new-properties.component';
import { PropertiesMapviewComponent } from './properties-mapview/properties-mapview.component';
import { NewPropertyDetailsComponent } from './new-property-details/new-property-details.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'masterdata',
        component: MasterDataComponent
      },
      {
        path: 'viewproject',
        component: PropertyDetailsComponent
      },
      {
        path: 'add-property',
        component: AddPropertiesComponent
      },
      {
        path: 'user-profile',
        component: UserProfileComponent
      },
      {
        path: 'properties-list',
        component: PropertyListComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'enquiries',
        component: EnquiriesComponent
      },
      {
        path : 'privacy-policy',
        component : PrivacyPolicyComponent
      },
      {
        path: 'add-newproperty',
        component: AddNewPropertiesComponent
      },
      {
        path : 'map-view',
        component : PropertiesMapviewComponent
      },
      {
        path: 'property-details',
        component : NewPropertyDetailsComponent
      }
    ]
  },
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: 'login',
        component: LoginNewComponent,
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
