<div>
  <div class="property-address">
    <h3 class="User_settings-heading">Profile Details</h3>
  </div>
  <div class="main">
    <section class="userDetails__container">
      <div class="userDetails__imageContainer">
        <ng-container *ngIf="userDetails?.profileImage">
          <img [src]="userDetails?.profileImage" alt="Image" />

      </ng-container>
        <ng-container *ngIf="!userDetails?.profileImage">
          <fa-icon [icon]="userCircleIcon" style="color: gray; font-size: 100px">
          </fa-icon>
        </ng-container>
      </div>
      <div class="userDetails__details mt-4">
        <div>
          <span class="profile-label-color pb-2">Name</span>: {{ userDetails?.name }}
        </div>
        <div>
          <span class="profile-label-color  pb-2">Mobile Number</span>: {{ userDetails?.phoneNumber }}
        </div>
        <div>
          <span class="profile-label-color  pb-2">Email</span>: {{ userDetails?.email}}
        </div>
      </div>
      <div class="userDetails__editbtn_container">
        <span class="userDetails__editbtn" (click)="toggleEdit()">
          Edit
        </span>
      </div>
    </section>
  </div>
</div>
<div class="overlay" [class.show]="isEditUserPanelOpen"></div>
<div class="off-canvas width-450" [class.show]="isEditUserPanelOpen">
  <div class="off-canvas-content">
    <app-user-profile-edit *ngIf="isEditUserPanelOpen" [userDetails]="userDetails"
      (closePanel)="closeAddUserPanel()"  (profileUpdated)="onProfileUpdated()"></app-user-profile-edit>
  </div>
</div>
