import { Component } from '@angular/core';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrl: './customer-reviews.component.scss'
})
export class CustomerReviewsComponent {

  data = [
    [{
      "text": "Whether you're buying, selling, or investing, this platform is the ultimate partner for success in real estate.",
      "image": "../../assets/images/customer-1.jpg",
      "name": "Ramachandra"
    },
    {
      "text": "Finding our dream home felt effortless with this platform! The intuitive interface and up-to-date listings made the entire process enjoyable and stress-free.",
      "image": "../../assets/images/customer-2.jpg",
      "name": "Ishaan"
    },
    {
      "text": "This platform helped me connect with reliable agents who truly cared about my needs. I couldn’t have asked for a better real estate experience.",
      "image": "../../assets/images/customer-3.jpg",
      "name": "Nilam Chowhan"
    }],
    [{
      "text": "This site made buying my first investment property so much easier. The data and tools gave me confidence in every decision I made.",
      "image": "../../assets/images/customer-4.jpg",
      "name": "Abhishek"
    },
    {
      "text":"I love how transparent this platform is. From pricing comparisons to detailed property histories, everything I needed was just a click away.",
      "image": "../../assets/images/customer-5.jpg",
      "name": "Ankit Tiwari"
    },
    {
      "text": "Listing my property was so simple!  Within hours,  I had multiple inquiries.   The platform’s tools truly made my home stand out.",
      "image": "../../assets/images/customer-6.jpg",
      "name": "Rohit Kashyap"
    }],
    []
  ];

  activeIndex = 0;
  itemsPerPage = 2;

  get paginatedData() {
    const start = this.activeIndex * this.itemsPerPage;
    return this.data.slice(start, start + this.itemsPerPage);
  }

}
