<div class="properties-heading">Add New Property</div>
<div class="mainblock">
    <div class="col add-property-mainblock">
        <div class="container my-5 main_content">
            <form>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <app-input-filed label="Project Name"></app-input-filed>
                    </div>
                    <div class="col-md-6">
                        <app-input-filed label="Builder/ Developer Name"></app-input-filed>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <app-input-filed label="Property Type" placeholder="Apartment"></app-input-filed>
                    </div>
                    <div class="col-md-6">
                        <div class="inputField__label">
                            Promotion Category
                        </div>
                        <select id="promotionCategory" class="addProperty__dropdownOptions">
                            <option value="" disabled selected></option>
                            <option value="category1"> Flag Ship</option>
                            <option value="category2">Featured</option>
                            <option value="category3">Recent</option>
                            <option value="category4">All</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-6">
                        <app-input-filed label="Location"
                            placeholder="Tellapur, Nallagandla, West Hyderabad"></app-input-filed>
                    </div>
                    <div class="col-md-6">
                        <app-input-filed label="Sub Location"></app-input-filed>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="col-md-6">
                        <app-input-filed label="Brief Description"></app-input-filed>
                    </div>
                </div>

                <div class="mb-3 description-box">
                    <label>About the Project</label>
                    <textarea class="form-control" id="aboutProject" rows="3"
                        placeholder="Enter details about the project"></textarea>
                </div>

                <div class="mb-3">
                    <label class="form-label unit_type">Unit Types:</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitType2BHK" value="2BHK">
                            <label class="form-check-label" for="unitType2BHK">2 BHK</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitType3BHK" value="3BHK">
                            <label class="form-check-label" for="unitType3BHK">3 BHK</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitType4BHK" value="4BHK">
                            <label class="form-check-label" for="unitType4BHK">4 BHK</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitType5BHK" value="5BHK">
                            <label class="form-check-label" for="unitType5BHK">5 BHK</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitTypeDuplex"
                                value="Duplex">
                            <label class="form-check-label" for="unitTypeDuplex">Duplex</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="unitType" id="unitTypeTriplex"
                                value="Triplex">
                            <label class="form-check-label" for="unitTypeTriplex">Triplex</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <!-- <button type="button" class="btn btn-outline-warning button_size">Add Unit</button> -->
                            <app-common-button buttonClass="addPropertyUnitButton"
                                buttonLabel="Add Unit"></app-common-button>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label unit_type">Unit Sizes:</label>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">Unit 1:</label>
                            <input type="text" class="form-control input_size">
                            <p class="text_size">sqft</p>

                            <span>
                                <input type="text" class="form-control input_size2">
                                <p class="text_size2">sqft</p>
                            </span>
                        </div>
                        <div class="col-md-2">
                            <!-- <button type="button" class="btn btn-outline-warning button_size">Add Size</button> -->
                            <app-common-button buttonClass="addPropertyUnitButton"
                                buttonLabel="Add Size"></app-common-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">Unit 2:</label>
                            <input type="text" class="form-control input_size">
                            <p class="text_size">sqft</p>

                            <span>
                                <input type="text" class="form-control input_size2">
                                <p class="text_size2">sqft</p>
                            </span>
                        </div>
                        <div class="col-md-2">
                            <!-- <button type="button" class="btn btn-outline-warning button_size">Add Size</button> -->
                            <app-common-button buttonClass="addPropertyUnitButton"
                                buttonLabel="Add Size"></app-common-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label class="form-label">Unit 3:</label>
                            <input type="text" class="form-control input_size">
                            <p class="text_size">sqft</p>

                            <span>
                                <input type="text" class="form-control input_size2">
                                <p class="text_size2">sqft</p>
                            </span>
                        </div>
                        <div class="col-md-2">
                            <!-- <button type="button" class="btn btn-outline-warning button_size">Add Size</button> -->
                            <app-common-button buttonClass="addPropertyUnitButton"
                                buttonLabel="Add Size"></app-common-button>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-md-4">
                        <app-input-filed label="Number of towers:"></app-input-filed>
                    </div>
                    <div class="col-md-2">
                        <app-input-filed label="Floors in Tower 1"></app-input-filed>
                    </div>
                    <div class="col-md-2">
                        <app-input-filed label="Floors in Tower 2"></app-input-filed>
                    </div>
                    <div class="col-md-2">
                        <app-input-filed label="Floors in Tower 3"></app-input-filed>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- Facing Section -->

                    <div class="col-md-6">
                        <label class="form-label unit_type">Facing:</label>
                    
                        <div class="mb-3" *ngFor="let unit of units; let i = index">
                            <label class="form-label">Unit {{ i + 1 }}:</label>
                            <div style="display: flex; gap: 5px;">
                                <app-common-button 
                                    *ngFor="let direction of directions" 
                                    [buttonClass]="selectedFacing[i] === direction ? 'selectedButton' : 'addPropertyButton'"
                                    [buttonLabel]="direction"
                                    (click)="selectFacing(i, direction)">
                                </app-common-button>
                            </div>
                        </div>
                    </div>
                    <!-- RERA Approval Section -->
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label">Is the Project RERA Approved?</label>
                            <div class="btn-group" role="group" style="margin-left: 20px;">
                                <input type="radio" class="btn-check" name="reraApproved" id="reraYes"
                                    autocomplete="off">
                                <label class="btn btn-outline-secondary" for="reraYes">YES</label>

                                <input type="radio" class="btn-check" name="reraApproved" id="reraNo"
                                    autocomplete="off">
                                <label class="btn btn-outline-secondary" for="reraNo">NO</label>
                            </div>
                        </div>
                        <div class="mb-3" style="display: flex; align-items: center;">
                            <app-input-filed label="RERA Number(If Yes)"></app-input-filed>
                        </div>
                    </div>
                </div>


                <div class="price-range">
                    <label class="form-label unit_type">Price Range:</label>
                    
                    <div class="row mb-2">
                        <div class="col-md-1">
                            <label class="form-label">Unit 1:</label>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MIN"> -->
                             <app-input-filed placeholder="MIN"></app-input-filed>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MAX"> -->
                            <app-input-filed placeholder="MAX"></app-input-filed>
                        </div>
                        <div class="col-md-1 text-center align-self-center">
                            <!-- <label>or</label> -->
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="Price"> -->
                            <app-input-filed placeholder="Price"></app-input-filed>
                        </div>
                    </div>
                
                    <div class="row mb-2">
                        <div class="col-md-1">
                            <label class="form-label">Unit 2:</label>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MIN"> -->
                            <app-input-filed placeholder="MIN"></app-input-filed>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MAX"> -->
                            <app-input-filed placeholder="MAX"></app-input-filed>
                        </div>
                        <div class="col-md-1 text-center align-self-center incr_size">
                            <label>or</label>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="Price"> -->
                            <app-input-filed placeholder="Price"></app-input-filed>
                        </div>
                    </div>
                
                    <div class="row mb-2">
                        <div class="col-md-1">
                            <label class="form-label">Unit 3:</label>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MIN"> -->
                            <app-input-filed placeholder="MIN"></app-input-filed>
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="MAX"> -->
                            <app-input-filed placeholder="MAX"></app-input-filed>
                        </div>
                        <div class="col-md-1 text-center">
                            <!-- <label>or</label> -->
                        </div>
                        <div class="col-md-2">
                            <!-- <input type="text" class="form-control" placeholder="Price"> -->
                            <app-input-filed placeholder="Price"></app-input-filed>
                        </div>
                    </div>
                </div>
                
                <div class="mb-4">
                    <label class="form-label"><strong>Amenities:</strong></label>
                    <div class="row g-3">
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-4">
                            <app-input-filed></app-input-filed>
                        </div>
                    </div>
                    <!-- <button type="button" class="btn btn-outline-warning button_size">Add a row</button> -->
                    <app-common-button buttonClass="addPropertyUnitButton" buttonLabel="Add a row"></app-common-button>
                </div>

                <!-- Address & Location Section -->
                <div class="mb-4">
                    <label class="form-label"><strong>Address & Location:</strong></label>
                    <div class="col-md-3">
                        <app-input-filed label="Pin Location :" placeholder="pin location"></app-input-filed>
                    </div>
                    <div class="mb-3">
                        <app-input-filed label="Address :" placeholder="Address"></app-input-filed>
                    </div>
                    <div class="row g-3">
                        <div class="col-md-3">
                            <app-input-filed label="City"></app-input-filed>
                        </div>
                        <div class="col-md-3">
                            <app-input-filed label="State"></app-input-filed>
                        </div>
                        <div class="col-md-3">
                            <app-input-filed label="Country"></app-input-filed>
                        </div>
                        <div class="col-md-3">
                            <app-input-filed label="Pincode"></app-input-filed>
                        </div>
                    </div>
                </div>

                <!-- Vicinity/Locality Section -->
                <div class="mb-4">
                    <label class="form-label"><strong>Vicinity/Locality:</strong></label>
                    <div class="row g-3">
                        <div class="col-md-3">

                            <app-input-filed label="Facilities"></app-input-filed>
                            <app-input-filed></app-input-filed>
                        </div>
                        <div class="col-md-3">

                            <app-input-filed label="Distance"></app-input-filed>
                            <app-input-filed></app-input-filed>
                        </div>

                    </div>
                    <!-- <button type="button" class="btn btn-outline-warning button_size">Add item</button> -->
                    <app-common-button buttonClass="addPropertyUnitButton" buttonLabel="Add Item"></app-common-button>
                </div>

                <div class="row mb-4">
                    <label class="form-label"><strong>Uploads:</strong></label>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label ">Brochure:</label>
                            </div>
                            <div class="col-md-6 upload_file">
                                <label for="brochure-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="brochure-upload" class="file-input" accept=".pdf, .doc, .docx" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Floor Plan:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="floorplan-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="floorplan-upload" class="file-input" accept=".pdf, .png, .jpg" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Building Plan:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="buildingplan-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="buildingplan-upload" class="file-input"
                                    accept=".pdf, .png, .jpg" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Video:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="video-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="video-upload" class="file-input" accept="video/*" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Gallery Pics:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="gallery-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="gallery-upload" class="file-input" accept="image/*" multiple />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Header Section Photos:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="header-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="header-upload" class="file-input" accept="image/*" multiple />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Logo:</label>
                            </div>
                            <div class="col-md-6">
                                <label for="logo-upload" class="custom-upload-button">Upload</label>
                                <input type="file" id="logo-upload" class="file-input" accept="image/*" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <!-- <button type="submit" class="btn btn-upload">Upload</button> -->
                    <app-common-button buttonClass="addPropertyUploadButton" buttonLabel="upload"></app-common-button>
                </div>
            </form>
        </div>
    </div>
</div>