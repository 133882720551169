import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService } from '../../services/property/property.service';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';


@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrl: './property-details.component.scss'
})
export class PropertyDetailsComponent {

  propertyId: any;
  verifyLoader: boolean = false;
  projectDetails: any;
  constructor(
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private priceFormatterService: PriceFormatterService
  ) { }

  ngOnInit(): void {
    this.verifyLoader = true;
    this.activatedRoute.queryParams?.subscribe((res: any) => {
      this.showPropertyDetails(res?.pid)
    })
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  showPropertyDetails(id: any) {
    this.propertyService.getsubPropertyList(id).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.projectDetails = res.data;
          this.projectDetails.startingPrice = this.priceFormatterService.formatPrice(this.projectDetails.startingPrice);
          this.verifyLoader = false;
        }
      },
      error: (error: any) => {
        this.verifyLoader = false;
        console.error('Error fetching property details:', error);
      }
    });
  }


  back() {
    this.router.navigate(['properties-list']);
  }


}
