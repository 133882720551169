import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceFormatterService {

  constructor() { }

  formatPrice(price: number): string {
    if (price >= 10000000) {
      const croreValue = price / 10000000;
      const formattedValue = (Math.floor(croreValue * 10) / 10).toFixed(croreValue % 1 === 0 ? 0 : 1);
      return formattedValue + 'Cr';
    } else if (price >= 100000) {
      return (price / 100000).toFixed(0) + 'L';
    } else {
      return price?.toString();
    }
  }
}

