<google-map width="100%" height="100%" [center]="center" [zoom]="zoom" (mapClick)="onMapClick($event)">
  <map-marker-clusterer imagePath="../../assets/images/m">
    <map-marker #infoWindow="mapMarker" *ngFor="let marker of markers; index as i"
    [position]="marker.position"
    (mapClick)="openInfoWindow(infoWindow,i)"  [options]="{
      animation: google.maps.Animation.DROP,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 7,
        fillColor: 'red',
        fillOpacity: 1,
        strokeColor: 'red',
        strokeWeight: 2
      }
    }">
    </map-marker>
  </map-marker-clusterer>
  <map-info-window>
    <div style="width: 301px;">
      <img [src]="markers[index]?.data.projectImage.objectUrl" alt="" class="img-w">
      <p>Project Name: {{markers[index]?.data.projectName}}</p>
      <p>Price: {{priceFormatterService.formatPrice(markers[index]?.data.minimumPrice)}}</p>
      <p>Size: {{markers[index]?.data.minimumSize.value}} {{markers[index]?.data.minimumSize.unitType}}</p>
      <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Show Details"
      (click)="openProjectView(markers[index])"></app-common-button>
    </div>
  </map-info-window>
</google-map>
