import { Component, OnInit, ViewChild } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import {GoogleMap, MapMarker, MapMarkerClusterer, MapInfoWindow} from '@angular/google-maps';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Router } from '@angular/router';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';

@Component({
  selector: 'app-properties-mapview',
  templateUrl: './properties-mapview.component.html',
  styleUrl: './properties-mapview.component.scss',
})
export class PropertiesMapviewComponent implements OnInit {
  google: any = google
  center: google.maps.LatLngLiteral = { lat: 17.406498, lng: 78.47724389999999 };
  zoom = 12;
  markers: any = [];
  activeCard: any = null;
  cardPosition = { top: '0px', left: '0px' };
  index: any;
  selectedPropertyId: any;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow !: MapInfoWindow;

  constructor(private propertyService:PropertyService,
    public priceFormatterService: PriceFormatterService,
    private router: Router
   ){}

  ngOnInit() {
    this.getBirdViewData();
  }

  openProjectView(property: any) {
    this.router.navigate(['/property-details'], { queryParams: { pid: property.data._id } });
  }

  getBirdViewData(){
    this.propertyService.getBirdView().subscribe({
      next : (res: any)=>{
        if(res?.data){
          res.data.forEach((location: any) => {
            let obj = {
              position : {
                lat : location?.address?.location[0],
                lng : location?.address?.location[1]
              },
              data : {
                ...location
              }
            }
            this.markers.push(obj);
          });
        }
      }
    })
  }

  onMarkerClick(event: any, markerData: any): void {
    this.activeCard = markerData;
    this.cardPosition = {
      top: `${event.domEvent.y}px`,
      left: `${event.domEvent.x}px`
    };
  }

  onMapClick(e: any) {
    this.activeCard = null;
  }

  openInfoWindow(infoWindow:MapMarker, index: any) {
    this.index = index;
    this.infoWindow.open(infoWindow);
  }
}


