import { Component, OnInit } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { Router } from '@angular/router';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';
@Component({
  selector: 'app-featured-properties',
  templateUrl: './featured-properties.component.html',
  styleUrl: './featured-properties.component.scss'
})
export class FeaturedPropertiesComponent implements OnInit {

  constructor (  private propertyService: PropertyService,
    private router        : Router,
    private priceFormatterService: PriceFormatterService

  ){}

  ngOnInit(): void {
    this.getFeaturedProperties()
  }
  
  featuredProperties : any ;


  getFeaturedProperties() {
    this.propertyService.featuredProperties()?.subscribe({
      next: (res: any) => {
        this.featuredProperties = res?.data.map((property: any) => {
          return {
            ...property,
            formattedPrice: this.priceFormatterService.formatPrice(property.minimumPrice)
          };
        });
      },
      error: (error: any) => {
        console.error('Error fetching featured properties', error);
      }
    });
  }

  openProjectView(property: any) {
    this.router.navigate(['/property-details'], {queryParams: {pid: property._id}})
  }
}
