import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute : ActivatedRoute) { }
  ngOnInit(): void {
    this.activatedRoute.queryParams?.subscribe(params => {
      const section = params['section'];
      if (section) {
        setTimeout(() => {
          const element = document.getElementById(section);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100); 
      }
    });
  }


  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else if (this.router.url.includes('properties-list') || this.router.url.includes('privacy-policy')) {
      this.router.navigate(['/home'], { queryParams: { section } });
    } else {
      this.router.navigate([], { queryParams: { section }, relativeTo: this.activatedRoute });
    }

  }

  privacyPolicy(): void {
    if (this.router.url.includes('privacy-policy')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.router.navigate(['privacy-policy']);
    }
  }

  pricingList(){
    this.router.navigate(['properties-list']);
  }

}
