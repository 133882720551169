<div class="modal" tabindex="-1" role="dialog" [class.show]="isEnquiry">
  <div class="modal-dialog custom-modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title"> <strong> Property Enquiry </strong></p>
        <img class="img-move" src="../../assets/images/icons8-cancel.svg" (click)="close()">
      </div>
      <div class="modal-body" *ngIf="enquiryForm">
        <form [formGroup]="enquiryForm">
          <div class="inp-fld">
            <app-input-filed label="Mobile Number" placeholder="Number"
              [control]="$any(enquiryForm)?.controls['phoneNumber']" [starIcon]="'*'" [allowedType]="'number'"
              [maxDigits]="10"></app-input-filed>
              <div>
                <div class="addProperty__dropdown addProperty-dropdown-description">
                    <label class="addProperty__dropdownLabel">Message <span
                            style="color:red;">*</span>
                    </label>
                    <textarea class="addProperty-description" placeholder="Enter message"
                        formControlName="message" appNoLeadingSpaces ></textarea>
                        <div class="errorText"
                        *ngIf="enquiryForm.controls['message'].invalid && (enquiryForm.controls?.['message']?.dirty || enquiryForm.controls['message'].touched)">
                        
                        <div *ngIf="enquiryForm.controls['message'].errors?.['required']">
                            <small class="text-danger pb-4">Message is required</small>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="errorText"
            *ngIf="enquiryForm.controls['phoneNumber'].invalid && (enquiryForm.controls?.['phoneNumber']?.dirty || enquiryForm.controls['phoneNumber'].touched)">
            <div class="pb-2" *ngIf="enquiryForm.controls['phoneNumber'].errors?.['pattern']">
              <small class="text-center text-danger pb-10">Please enter valid mobile number</small>
            </div>
            <div class="pb-2" *ngIf="enquiryForm.controls['phoneNumber'].errors?.['required']">
              <small class="text-center text-danger mb-10">Mobile number is required</small>
            </div>
          </div>
          <div  class="pb-2" *ngIf="errorMessage">
            <small class="text-center text-danger mb-10">{{ errorMessage }}</small>
          </div>
          <div class="move-button">
            <app-common-button buttonLabel="Submit"  (click)="onSubmit()" [loadingIcon]="loader" buttonClass="smallButton"
            [disabled]="enquiryForm.invalid" ></app-common-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>