import { Component } from '@angular/core';

@Component({
  selector: 'app-add-new-properties',
  templateUrl: './add-new-properties.component.html',
  styleUrl: './add-new-properties.component.scss'
})
export class AddNewPropertiesComponent {

  directions: string[] = ['North', 'East', 'West', 'South'];
  selectedFacing: string[] = [];
  units: number[] = [1, 2, 3]; // Assuming you have 3 units

  constructor() {
    this.selectedFacing = Array(this.units.length).fill(''); // Initialize selectedFacing for each unit
  }

  selectFacing(unitIndex: number, direction: string) {
    this.selectedFacing[unitIndex] = direction;
  }
}
