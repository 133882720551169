<div class=" m-3">
    <div class="btn-div">
        <div>
            <app-common-button buttonClass="view-enquiry" buttonLabel="Add New"
                (click)="openOverlay('Add','')"></app-common-button>
        </div>
    </div>
    <div class="master-data">
        <div class="company-head">
            <div class="lookupDataList __section">
                <div class="lookupDatalist__management listPage__Description">
                    <div class="masterDataTitle">
                        Assets
                    </div>
                </div>
            </div>
        </div>
        <div class="navbar-prop">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item " [ngClass]="{ active: tabvalue === 'assets'}">
                    <a data-toggle="tab" (click)="onTabSelect('assets')">Assets</a>
                </li>
                <li class="nav-item" [ngClass]="{ active: tabvalue === 'module'}">
                    <a data-toggle="tab" (click)="onTabSelect('module')">Module</a>
                </li>
                <li class="nav-item" [ngClass]="{ active: tabvalue === 'submodule'}">
                    <a data-toggle="tab" (click)="onTabSelect('submodule')">Sub Module</a>
                </li>
            </ul>
        </div>
        <div *ngIf="assetsList && assetsList?.length > 0">
            <table class="custom-table-list" aria-label="cred_table" *ngIf="tabvalue === 'assets'">
                <thead>
                    <tr class="custom-table-list-headings">
                        <th class="custom-col-width-30">Name</th>
                        <th class="custom-col-width-30">Created on</th>
                        <th class="custom-col-width-30">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="custom-table-list-row-main" *ngFor="let asset of assetsList">
                        <td class="custom-col-width-30">{{asset.name}}</td>
                        <td class="custom-col-width-30">{{asset.created | date : "MMM d, y, h:mm:ss a"}}</td>
                        <td class="custom-col-width-30">
                            <span (click)="openOverlay('edit',asset)" data-bs-toggle="tooltip" ngbTooltip="Edit"
                                [placement]="'left'">
                                <div class="edit">
                                    <img src="../../../assets/images/edit.png">
                                </div>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isOverlayOpen">
            <div class="overlay {{isOverlayOpen ? 'show' : ''}} ">
            </div>
            <div class="off-canvas width-450 {{isOverlayOpen ? 'show' : ''}}">
                <div class="off-canvas-content">
                    <app-add-master-data [selectedTypeData]="selectedTypeData" (closePanel)="closeOverlay($event)"
                        [selectedType]="tabvalue"></app-add-master-data>
                </div>
            </div>
        </div>

        <div *ngIf="loader && tabvalue === 'assets'">
            <div class="custom-col-width-100 d-flex justify-content-center" style="color: #fbb416;">
                <app-common-icon [loadingIcon]="loader"></app-common-icon>
            </div>
        </div>
        <div *ngIf="!loader && assetsList?.length === 0">
            <div class="pt-3 custom-col-width-100 text-danger d-flex justify-content-center">
                No Assets Found!
            </div>
        </div>
        <div *ngIf="tabvalue === 'module'">
            <app-module-data (editModule)="editModule($event)"></app-module-data>
        </div>
        <div *ngIf="tabvalue === 'submodule'">
            <app-submodule-data (editSubModule)="editSubModule($event)"></app-submodule-data>
        </div>
    </div>
</div>