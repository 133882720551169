import { Component, OnChanges, OnInit, SimpleChanges, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { PropertyService } from '../../services/property/property.service';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from '../../services/filters/filter.service';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';
import { Subscription } from 'rxjs';
import { faHeart } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrl: './property-list.component.scss'
})
export class PropertyListComponent implements OnInit, OnChanges, OnDestroy {

  constructor(
    private propertyService: PropertyService,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private filterService: FilterService,
    private elementRef: ElementRef,
    private priceFormatterService: PriceFormatterService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  public readonly faSearch = faSearch;
  public readonly faHeart = faHeart;
  showSmallCapProperties: boolean = false;
  isAddProperty: boolean = false;
  propertyList: any[] = [];
  displayedPropertyList: any[] = [];
  categoryList: any[] = [];
  filteredPropertyList: any[] = [];
  selectedCategory: string = '';
  status: any;
  verifyLoader: boolean = false;
  isAdmin: boolean = false;
  isEnquiry: boolean = false;
  isEnquiryList: boolean = false;
  selectedPropertyId: any;
  isLoginModalVisible: boolean = false;
  enquiryListCount: number = 0;
  propertyCount: number = 0;
  filterObject: any = {};
  filterObject1: any = {};
  filteredParams: any = {};
  properties: any[] = [];
  amenities: any;
  amenitiesList: any;
  propertyType: any;
  dropdownOpen = false;
  selectedAmenities: string[] = [];
  private isLoading = false;
  filter = "";
  pageCount = 0;
  favproperty: boolean = false;
  subscription !: Subscription;

  ngOnInit(): void {
    this.getLocalStorageDetails();
    this.getCategoryList();
    this.getAmenitiesList();
    this.pageCount = 0;
    if (this.activatedRoute.snapshot.paramMap.get('data')) {
      var text = this.storageService.decrypt(this.activatedRoute.snapshot.paramMap.get('data') || '');
      if (text) {
        let encryptedData = JSON.parse(text);
        this.searchProperty(encryptedData);
      }
    }

    const storedFilter = localStorage.getItem('filterObject');
    if (storedFilter) {
      this.filterObject = JSON.parse(storedFilter);
      this.searchProperty(this.filterObject);
    } else {
      this.getPropertyList();
    }

    this.subscription = this.filterService.currentFilterObject.subscribe(filterObject => {
      this.pageCount = 0;
      if (filterObject) {
        this.searchProperty(filterObject);
      } else {
        this.getPropertyList();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  searchProperty(filterObject: any) {
    this.filterObject = filterObject;
    localStorage.setItem('filterObject', JSON.stringify(this.filterObject));
    if (filterObject.latitude && filterObject.latitude !== "null") {
      this.filteredParams.latitude = filterObject.latitude;
      this.filteredParams.longitude = filterObject.longitude;
    }
    if (filterObject.propertyType && filterObject.propertyType !== "null") {
      this.filteredParams.categoryName = filterObject.propertyType;
    }
    if (filterObject.amenities) {
      this.filteredParams.amenity_in = filterObject.amenities.map((item: any) => item._id.replace(/\s+/g, '-')).join(',');
    }
    if (filterObject.popularity && filterObject.popularity !== "null") {
      this.filteredParams.sort = filterObject.popularity;
      this.filteredParams.sortDir = filterObject.sortDir;
    }
    if (filterObject.minimumPrice != null) {
      this.filteredParams.minimumPrice = filterObject.minimumPrice;
    }
    if (filterObject.maximumPrice != null) {
      this.filteredParams.maximumPrice = filterObject.maximumPrice;
    }

    this.getPropertyList();
    this.filteredParams = {};
  }

  getLocalStorageDetails() {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  getPropertyList() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.verifyLoader = true;
    this.propertyService.getPropertyList(this.filteredParams, this.pageCount)?.subscribe({
      next: (res: any) => {
        this.verifyLoader = false;
        if (res) {
          const newProperties = res.data.map((property: any) => {
            property.minimumPrice = this.priceFormatterService?.formatPrice(property.minimumPrice);
            property.enquiryListCount = property.enquiresCount;
            property.favproperty = property.favproperty || false;
            return property;
          });

          if (this.pageCount === 0) {
            this.propertyList = newProperties;
          } else {
            this.propertyList = this.propertyList.concat(newProperties);
          }

          this.propertyCount = this.propertyList.length;
          this.isLoading = false;
        }
      },
      error: (error: any) => {
        this.verifyLoader = false;
        this.isLoading = false;
      },
    });
  }

  getCategoryList() {
    this.verifyLoader = true;
    this.propertyService.getCategoryList()?.subscribe({
      next: (res: any) => {
        this.verifyLoader = false;
        if (res) {
          this.categoryList = res.data;
          this.propertyType = this.categoryList.map((item: any) => item.name);
        }
        if (this.categoryList.length > 0) {
          this.setCategory(this.categoryList[0].name);
        }
      },
      error: (error: any) => {
      }
    })
  }

  addProperty() {
    this.isAddProperty = true;
    this.showSmallCapProperties = false;
    this.router.navigate(['add-property'])
  }

  filterProperties(): void {
    if (this.selectedCategory) {
      this.filteredPropertyList = this.propertyList.filter((property: { categoryName: string; }) => property.categoryName === this.selectedCategory);
    } else {
      this.filteredPropertyList = this.propertyList;
    }
  }

  setCategory(category: string): void {
    this.selectedCategory = category;
    this.filterProperties();
  }

  closeAddproperty() {
    this.isAddProperty = false;
  }

  openProjectView(property: any) {
    this.router.navigate(['/property-details'], { queryParams: { pid: property._id } })
  }

  openEnquiry(value: any) {
    if (this.checkLoginStatus) {
      this.selectedPropertyId = value?._id;
      this.isEnquiry = true;
    } else {
      this.isLoginModalVisible = true;
    }
  }

  openEnquiryList(value: any) {
    if (this.checkLoginStatus) {
      this.selectedPropertyId = value?._id;
      this.isEnquiryList = true;
    } else {
      this.isLoginModalVisible = true;
    }
  }

  openProjectModal() {
    this.isLoginModalVisible = true;
  }

  closeProjectModal() {
    this.isLoginModalVisible = false;
    this.getLocalStorageDetails();
  }

  closeEnquiry() {
    this.isEnquiry = false;
  }

  closeEnquiryList() {
    this.isEnquiryList = false;
  }

  get checkLoginStatus() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  getAmenitiesList() {
    this.propertyService.getAmenitiesList()?.subscribe({
      next: (res: any) => {
        this.amenitiesList = res?.data;
        this.amenities = this.amenitiesList.map((item: any) => item.name);
      },
      error: (error: any) => {
      }
    })
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  isSelected(amenity: string): boolean {
    return this.selectedAmenities.includes(amenity);
  }

  toggleSelection(amenity: string) {
    const index = this.selectedAmenities.indexOf(amenity);
    if (index === -1) {
      this.selectedAmenities.push(amenity);
    } else {
      this.selectedAmenities.splice(index, 1);
    }
  }


  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement) {
    const dropdownElement = this.elementRef.nativeElement.querySelector('.custom-dropdown');
    if ( dropdownElement && !dropdownElement.contains(target)) {
      this.dropdownOpen = false;
    }
  }

  onScroll() {
    if (this.propertyList.length % 20 == 0) {
      this.pageCount += 20;
      this.getPropertyList();
    }
  }

  onPropertyEditClicked(id: any) {
    this.router.navigate(['/add-property'], { queryParams: { pid: id } });
  }

  toggleFavorite(event: Event, value: any): void {
    { queryParms: { pid: value } };
    event.stopPropagation();
    if (this.checkLoginStatus) {
      value.favproperty = !value.favproperty;
      this.propertyService.selectFavorite(value._id).subscribe({
        next: (res: any) => {
          this.getPropertyList();
          this.verifyLoader = false;
        },
        error: (error: any) => {
          console.error(error);
        }
      });
    }
    else {
      this.openEnquiry(value);
    }
  }

  handleToggleFavourite(favState: boolean) {
    this.favproperty = favState;
    if (this.favproperty) {
      this.filteredParams.onlyFavourites = true;
    } else {
      delete this.filteredParams.onlyFavourites;
    }
    this.getPropertyList();
  }

}
